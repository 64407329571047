<template>
  <div data-app>
    <template>
      <v-container fluid>
        <v-row class="ma-0">
          <v-col class="pb-4 pl-0">
            <span class="text-h5">Tìm kiếm</span>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col cols="6" class="pa-0">
            <v-row class="ma-0">
              <v-col cols="3" class="pa-0">
                <span class="text-body-1">Chuyên mục</span>
              </v-col>
              <v-col cols="9" class="pa-0">
                <hnr-input @active="search" v-model="category"></hnr-input>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="align-center justify-center ma-0">
          <v-col cols="6" class="pa-0">
            <v-btn color="primary" @click="search">Tìm kiếm</v-btn>
          </v-col>
        </v-row>

      </v-container>
      <v-data-table
          :headers="headers"
          :items="topicsNew"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :server-items-length="total"
          @update:items-per-page="updateItemPerPage"
          @update:page="updatePage"
          :footer-props="{ itemsPerPageOptions	: [5, 10, 15, 20] }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách chủ đề
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" to="/news-category/add" target="_blank">Thêm mới</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.type_topic="{item}">
          <div v-if="item.type_topic == 1 ">
            Blog
          </div>
          <div v-if="item.type_topic == 2 ">
            Trắc nghiệm
          </div>
          <div v-if="item.type_topic == 3 ">
            Khác
          </div>
        </template>
        <template v-slot:item.created_at="{item}">
          {{ formatDateTime(item.created_at) }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-switch v-model="item.status" :disabled="true"></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
        <!--        <template v-slot:no-results>-->
        <!--          <v-alert :value="true" color="error" icon="warning">-->
        <!--            Your search for "{{ search }}" found no results.-->
        <!--          </v-alert>-->
        <!--        </template>-->
      </v-data-table>
      <!-- DIALOG DELETE  -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline"
          >Are you sure you want to delete this item?
          </v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
            >Cancel
            </v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK
            </v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {COUNT_CATEGORY} from "@/store/newscategory.module";
import {GET_TOPIC_ALL, DELETE_TOPIC} from "@/store/topic.module";
import {SET_LOADING} from "@/store/loading.module";
import HnrInput from '@/components/elements/hnr-input'

import {ERROR} from "@/store/alert.module";

export default {
  components: {
    HnrInput,
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: "",
        align: "start",
        sortable: false,
        value: "id"
      },
      {text: "Tên bài viết", value: "name", width: "20%"},
      {text: "Môn học", value: "subject.name"},
      {text: "Lớp học", value: "grade.name"},
      {text: "Chủ đề cha", value: "parent.name"},
      {text: "Phân Loại", value: "type_topic"},
      {text: "Người tạo", value: "publish_by"},
      {text: "Kích hoạct", value: "status"},
      {text: "Ngày tạo", value: "created_at", width: "8%"},
      {text: "Actions", value: "actions", sortable: false}
    ],
    category: "",
    dialog: false,
    editedIndex: -1,
    editedId: -1,
    dialogDelete: false,
    topicsNew: [],
  }),

  computed: {
    ...mapGetters({
      topics: "topicAll"
    }),
    total() {
      return this.$store.getters.categoryCount;
    },
  },
  mounted() {
    this.count();
    this.getNewsCategories();
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Chuyên mục", route: "Chuyên mục"}]);
  },
  watch: {
    topics: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.topicsNew = newVal
      }
    }
  },

  methods: {
    count() {
      var payload = {category: this.category};
      this.$store.dispatch(COUNT_CATEGORY, payload);
    },
    search() {
      this.count();
      this.topicsNew = [];
      this.getNewsCategories();
    },

    updateItemPerPage($event) {
      this.itemsPerPage = $event;
      this.getNewsCategories();
    },

    updatePage($event) {
      this.page = $event;
      this.getNewsCategories();
    },
    getNewsCategories() {
      var payload = {category: this.category, page: this.page, page_count: this.itemsPerPage};
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(GET_TOPIC_ALL, payload).then(() => {
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
    },
    editItem(item) {
      let routeData = this.$router.resolve({
        name: "category-detail",
        params: {slug: item.slug},
      });
      window.open(routeData.href, '_blank');
    },
    // addNewItem() {
    //   this.$router.push({name:'category-add'});
    // },
    deleteItem(item) {
      this.editedIndex = this.topics.indexOf(item);
      this.editedId = item.id;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch(DELETE_TOPIC, this.editedId).then((data) => {
        if (data.status) {
          this.topics.splice(this.editedIndex, 1);
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {
              root: true,
            });
          } else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }
        }
      });

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    formatDateTime(date) {
      if (!date) return null
      const newDate = new Date(date)
      const [year, month, day, hour, minute, millisecond] = [newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate(),
        newDate.getHours(), newDate.getMinutes(), newDate.getMilliseconds()]
      const hour_format = hour > 9 ? hour : `0${hour}`
      const minute_format = minute > 9 ? minute : `0${minute}`
      const millisecond_format = millisecond > 9 ? millisecond : `0${millisecond}`
      return `${hour_format}:${minute_format}:${millisecond_format} ${day}/${month}/${year}`
    }
  }
};
</script>
